import React from 'react';
import Typed from 'react-typed';

const AboutPublicSectorAndGovernment = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">

                    <div className="col-lg-12 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                {/* <h2 className="title">Unique Business <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Consulting.",
                                            "Finance.",
                                            "Agency.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2> */}
                                <p>IMT has a dedicated Public Sector Practice for more than twenty (20) years and offers IT Services and solutions for the Public Sector - Government, International Organizations and higher education segments across the world. Some of the major Service Offerings are in the area of ERP Implementation; Business Transformation; Enterprise Portals & Content Management Systems; Business Intelligence & Data warehousing solutions; Enterprise Application Integration and Middleware solutions; Infrastructure support; Robotic Process Automation; Artificial Intelligence, Machine Learning; Advance Analytics, Cloud Migration (AWS, Azure, IBM, Google); Geographical Information Systems and Application Development and Maintenance Services (ADMS).</p>
                                {/* <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences </p> */}
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="#"><span>More About Us</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutPublicSectorAndGovernment
