import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FaHandHoldingHeart, FaHandHoldingWater, FaHome, FaMotorcycle } from 'react-icons/fa';

const ServiceList = [
    {
        icon: <FaHome />,
        title: 'E-Governance',
        description: 'Addressing the transformation aspect of e-Governance through solution competency, range and depth of alliance partners and proven system integration capabilities.'
    },
    {
        icon: <FaMotorcycle />,
        title: 'Department of Motor Vehicles',
        description: 'Leveraging MOVES to support the vision of Department of Motor Vehicles (DMV) jurisdictions for multi-channel customer service and a true customer-centric system.'
    },
    {
        icon: <FaHandHoldingHeart />,
        title: 'Grants Management',
        description: 'Leveraging GIST™ (Grant Integrated Solution Template) - a pre-configured solution to address the end-to-end Grantor Management process.'
    },
    {
        icon: <FiCast />,
        title: 'Public Budgeting Formulation',
        description: 'Leveraging GIST™ (Grant Integrated Solution Template) - a pre-configured solution to address the end-to-end Grantor Management process'
    }
]

const FederalAndStateGovernmentServices = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default FederalAndStateGovernmentServices;