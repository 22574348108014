import React from 'react';
import { FiActivity, FiCast, FiDatabase, FiHelpCircle, FiMap, FiMessageCircle, FiServer, FiSettings } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiDatabase />,
        title: 'Data Warehouse as a Service',
        description: 'InfoMats rents out a full-scale data warehouse integrated with your existing BI and analytics infrastructure on a subscription fee basis.'
    },
    {
        icon: <FiMessageCircle />,
        title: 'Data warehouse advisory services',
        description: 'InfoMats’ team designs a data warehouse and plans out its implementation as well as renders advisory support while migrating or upgrading your legacy solution to optimize DWH performance and costs.'
    },
    {
        icon: <FiServer />,
        title: 'Data warehouse implementation',
        description: 'InfoMats team builds a DWH tailored to your unique data consolidation and storage needs and implements it into your ecosystem.'
    },
    {
        icon: <FiMessageCircle />,
        title: 'Data warehouse migration',
        description: 'InfoMats helps you optimize DWH performance and lower total cost of ownership by moving your existing on-premises data warehouse to the cloud with no business process disruptions.'
    },
    {
        icon: <FiSettings />,
        title: 'Data warehouse testing',
        description: 'InfoMats offers a comprehensive DWH testing set, which can include ETL/ELT testing, BI testing, DWH performance testing and security testing.'
    },
    {
        icon: <FiHelpCircle />,
        title: 'Data warehouse support',
        description: 'InfoMats provides DWH support to help you identify and solve DWH performance issues, achieve DWH stability for timely and quality data flow for business users, lower DWH storage and processing costs.'
    }
]

const DAWService = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div style={{height: '250px'}} className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default DAWService;