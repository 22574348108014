import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ChildSupportAndHealthcareServices from '../service/ChildSupportAndHealthcareServices';
import EducationServices from '../service/EducationServices';
import FederalAndStateGovernmentServices from '../service/FederalAndStateGovernmentsServices';


const PublicSectorAndGovernmentTab = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                {/* <div className="col-lg-5">
                                    <img className="radius-small" src='./images/tab/tabs-01.jpg' alt="Corporate React Template" />
                                </div> */}
                                <div className="col-lg-12 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Federal And State Governments</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Child Support & Healthcare</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Education</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                        <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <FederalAndStateGovernmentServices
                                                         serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                                         textAlign = "text-center"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <ChildSupportAndHealthcareServices
                                                             serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                                             textAlign = "text-center"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <EducationServices
                                                         serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                                         textAlign = "text-center"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default PublicSectorAndGovernmentTab
