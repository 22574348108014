import React from 'react';
import Typed from 'react-typed';

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                  

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Policy Pro Insurance Eco-system. <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "TIRA.",
                                            "Assessor.",
                                            "Agency.",
                                            "Broker.",
                                            "Insurance Company.",
                                            "NIDA.",
                                            "TRA.",
                                            "TAMESA.",
                                            "Customer."
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>Policy Pro bridges all key stakeholders in the insurance eco-system by providing with them a robust digital platform.</p>
                                {/* <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences </p> */}
                                {/* <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="#"><span>More About Us</span></a>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/stakeholders.png" alt="About Images" />
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
