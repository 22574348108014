import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        title: "Humble",
        description: "Improve and never forget the importance of our customers and colleagues.",
    },
    {
        id: "2",
        title: "Customer Focused",
        description: "Great discipline and ensure a world class customer experience.",
    },
    {
        id: "3",
        title: "Employee Satisfication",
        description: "Our greatest asset. We love and support our colleagues and operate without hierarchy.",
    },
    {
        id: "4",
        title: "Passionate",
        description: "We strive for excellence, go the extra mile and have fun in what we do.",
    },
    {
        id: "4",
        title: "Speed",
        description: "Fast is better than slow but we will not compromise on quality.",
    },
    {
        id: "4",
        title: "Integrity",
        description: "We are consistent, honest and fair and always do what is right.",
    },
    {
        id: "4",
        title: "Discipline",
        description: "In everything we do. We accept responsibility and deliver on all of our commitments.",
    },
]

const CoreValuesTimeline = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h6 className="title">{data.title}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{data.description}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default CoreValuesTimeline;