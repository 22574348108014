import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import TabThree from '../elements/tab/TabThree';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import SlipFour from "../elements/split/SlipFour";
import SlpitOne from "../elements/split/SlpitOne";
import AdvancePricingOne from "../elements/advancePricing/AdvancePricingOne";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";
import PortfolioOne from "../elements/portfolio/PortfolioOne";

import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import ServiceTwo from '../elements/service/ServiceTwo';
import ServiceOne from '../elements/service/ServiceOne';
import ICTAndCyberSecurityService from '../elements/service/ICTAndCyberSecurityService';
var BlogListData = BlogClassicData.slice(0, 3);


const ICTAndCyberSecurity = () => {
    return (
        <>
            <SEO title="ICT & Cyber Security" />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-18.webp)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="title display-one">ICT <br /> <span className="theme-gradient">{""}</span> & <span className="theme-gradient">Cyber Security</span>.</h1>
                                    <p className="description">InfoMats has been successfully delivering cyber security services to Fortune 1000 clients globally for more than two decades.</p>
                                    {/* <div className="button-group">
                                        <Link className="btn-default btn-medium btn-icon" to="#">Purchase Now <i className="icon"><FiArrowRight /></i></Link>
                                        <Link className="btn-default btn-medium btn-border btn-icon" to="#">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "ICT And Cyber Security"
                                    title = "What We Offer."
                                    description = ""
                                />
                            </div>
                        </div>
                        {/* <TabThree /> */}
                        {/* <ServiceOne/> */}
                        <ICTAndCyberSecurityService
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--20 rbt-border"
                            textAlign = "text-start"
                            />
                    </div>
                </div>
                {/* End Elements Area  */}


                {/* Start Elements Area  */}
                {/* <div className="rwt-split-area no-radius">
                    <div className="wrapper">
                        <SlpitOne />
                    </div>
                </div> */}
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                {/* <div className="rwt-split-area">
                    <div className="wrapper">
                        <SlipFour />
                    </div>
                </div> */}
                {/* End Elements Area  */}

                

                {/* <div className="main-content">
                    <div className="rwt-elements-area rn-section-gap">
                        <div className="container-fluid plr--30">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Portfolio Box Layout"
                                        title = "Happily Clients Complete <br /> Our Business Project!"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <PortfolioOne Column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay" />
                        </div>
                    </div>
                </div>
                
                <Separator /> */}
                {/* Start Elements Area  */}
              
                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latests News"
                                    title = "Latest News From The Digital World."
                                    description = ""
                                />
                            </div>
                        </div>
                        {/* <div className="row row--15 mt--10">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div> */}
                    </div>
                </div> 


                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default ICTAndCyberSecurity
