import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BankingServices from '../service/BankingServices';
import FinancialServices from '../service/FinancialServices';
import InsuranceServices from '../service/InsuranceServices';
import ServiceFive from '../service/ServiceFive';
import ServiceFour from '../service/ServiceFour';
import ServiceOne from '../service/ServiceOne';
import ServiceThree from '../service/ServiceThree';
import ServiceTwo from '../service/ServiceTwo';

const BFSITab = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                {/* <div className="col-lg-5">
                                    <img className="radius-small" src='./images/tab/tabs-01.jpg' alt="Corporate React Template" />
                                </div> */}
                                <div className="col-lg-12 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Banking</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Financial Services</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Insurance</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                        <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>We help banks transform their operations, bring innovation, drive profitability and optimize resources.</p>
                                                        <BankingServices
                                                         serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                                         textAlign = "text-center"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>We help financial services firm increase profitability, reduce complexity and prepare for the digital future.</p>
                                                        <FinancialServices
                                                         serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                                         textAlign = "text-center"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <InsuranceServices
                                                         serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                                         textAlign = "text-center"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default BFSITab
