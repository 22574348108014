import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const InsuranceTechnologyAccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    What are our Capabilities?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <h4 className="title">Create engaging and disruptive experiences with innovative insurance technology.</h4>
                        <p className="description">Our offerings for the Insurance industry caters to imperatives including - Underwriting expertise through data augmentation, modernize core systems; Agile product design and development; Optimize cost of operations in core process areas; Robust customer experience; Empower intermediaries.</p>
                        <p className="description">Our mission is to provide cutting-edge personalized experiences to customers through innovative platforms by simplifying searching, sharing, and consumption of rich content via diverse devices and platforms.</p>
                    </Card.Body>
                    {/* <Card.Body>Our offerings for the Insurance industry caters to imperatives including - Underwriting expertise through data augmentation, modernize core systems; Agile product design and development; Optimize cost of operations in core process areas; Robust customer experience; Empower intermediaries. </Card.Body> */}

                </Accordion.Collapse>
            </Card>

            {/* <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    What is our goal in insurance industry?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Create engaging and disruptive experiences with innovative insurance technology.</Card.Body>
                </Accordion.Collapse>
            </Card> */}

            {/* <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    How can I get the customer support?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>After purchasing the product need you any support you can be share with us with sending mail to rainbowit10@gmail.com.</Card.Body>
                </Accordion.Collapse>
            </Card> */}

            {/* <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Can I get update regularly and For how long do I get updates?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Yes, We will get update the Trydo. And you can get it any time. Next time we will comes with more feature. You can be get update for unlimited times. Our dedicated team works for update.</Card.Body>
                </Accordion.Collapse>
            </Card> */}

        </Accordion>
    )
}
export default InsuranceTechnologyAccordionOne

