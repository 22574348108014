import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const BankingSolutionsTab = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="col-lg-2">
                        <div className="advance-tab-content advance-tab-content-1">
                            {/* <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/tabs-02.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel> */}
                            
                            {/* <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/tabs-03.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel> */}

                            {/* <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/tab/tabs-04.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel> */}
                        </div>
                    </div>
                    <div className="col-lg-8 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1 right-align">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Internet Banking.</h4>
                                        <p className='description'>Answer the ever-growing customers’ needs for secure 24/7 access to their banking accounts with custom internet banking</p>
                                        <p className="description">Whether you want to implement a solution from scratch or update your current obsolete software, InfoMats will provide you with rich functional scope and ensure implementation of the best online banking practices. With custom internet banking solutions, your clients will be able to enjoy the following functionality:</p>
                                        <ul className="list-icon">
                                            <li><span className="icon"><FiCheck /></span>Get detailed information about their checking, savings and credit accounts.</li>
                                            <li><span className="icon"><FiCheck /></span> Set up limits for their cards.</li>
                                            <li><span className="icon"><FiCheck /></span> Make payments</li>
                                            <li><span className="icon"><FiCheck /></span> Transfer money between banking accounts.</li>
                                        </ul>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Mobile Banking.</h4>
                                        <p className='description'>Answer the ever-growing customers’ needs for secure 24/7 access to their banking accounts with custom internet banking</p>
                                        <p className="description">Backed by 17 years of experience in mobile app development, InfoMats will help you implement custom mobile banking software tailored to your specific needs. Our software solutions vary from basic to A-class mobile banking apps and include both core and advanced functionality (e.g., personal financial management, loyalty program integration, personalized bank product offering, augmented reality, and more).</p>
                                    </div>    
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Customer Portal</h4>
                                        <p className="description">Build B2C banking portals for ongoing communication and long-term relationships with your customers, or B2B portals with role-based content personalization, multiple logins for customer employees, clear portfolio manager, financial planning and other custom functions.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Banking CRM</h4>
                                        <p className="description">Use strong capabilities of Dynamics 365 and Salesforce to enhance generation of customer-related data and effectively communicate with both prospects and current customers.</p>
                                        <p className="description">With 14 years in CRM consulting, InfoMats can boost your effectiveness in various customer-facing activities such as:</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Banking Data Analytics</h4>
                                        <p className="description">Tap into customer trends and liquidity forecasts, drive revenue and mitigate risks by converting raw data into actionable, reliable and consistent insights.</p>
                                        <p className="description">InfoMats will help you implement the following types of bank data analytics:</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Lending Software</h4>
                                        <p className="description">Take fast and well-informed loan decisions, mitigate risks and reduce time and costs of loan delivery.</p>
                                    </div>   
                                </Tab>
                                 <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Payment Software</h4>
                                        <p className="description">Harness the power of digital payments.</p>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>
    )
}
export default BankingSolutionsTab;
