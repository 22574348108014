import React from 'react';
import { FiActivity, FiAlertOctagon, FiBarChart, FiCast, FiCloud, FiDatabase, FiMap, FiMessageSquare, FiServer, FiSettings, FiShield, FiSmartphone, FiTool, FiUmbrella, FiWifi } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Advanced threat management (SIEM, SOC)',
        description: 'Our ATM solutions enable clients to be on alert 24 hours a day, 365 days a year to assess, prevent, detect and respond to data threats.'
    },
    {
        icon: <FiShield />,
        title: 'Application Security (VA/PT, DevSecOps)',
        description: 'Our clients bank on us for securing and monitoring their key applications such as ERPs, CRMs, and other platforms.'
    },
    {
        icon: <FiCloud />,
        title: 'Cloud Security',
        description: 'Bolt-on or ground up security for organizations transitioning to cloud environment is enabled with InfoMats’ cyber security solutions.'
    },
    {
        icon: <FiMessageSquare />,
        title: 'Cyber Advisory (Strategy, Assessments and Awareness)',
        description: 'With InfoMats’ Cyber Advisory services, you can define detailed cybersecurity strategies and plan the roadmap to attain business goals.'
    },
    {
        icon: <FiBarChart />,
        title: 'Cyber and Risk Analytics',
        description: 'Enable clients to monitor their KPIs and KRIs in an effective manner, bringing in alignment with their organizational and Cyber Risk strategies.'
    },
    {
        icon: <FiDatabase />,
        title: 'Data security and Privacy',
        description: 'Our data security & privacy services help clients stay in compliance with data requirements in an everchanging regulatory and threat landscape.'
    },
    {
        icon: <FiServer />,
        title: 'ERP Security',
        description: 'InfoMats’ ERP Security solutions help clients secure their ERP infrastructure by deploying the right governance, access and process controls.'
    },
    {
        icon: <FiUmbrella />,
        title: 'Governance, Risk and Compliance (Implementation and Risk Operations)',
        description: 'InfoMats leverages its consulting prowess and deep skills in risk management to deliver holistic end-to-end risk and compliance solutions.'
    },
    {
        icon: <FiActivity />,
        title: 'Identity and access management',
        description: 'We provide advisory, consulting & implementation of IAM services like privileged access and identity governance with our specialized resource pool.'
    },
    {
        icon: <FiWifi />,
        title: 'Infrastructure Security (Network Security)',
        description: 'InfoMats provides comprehensive & proactive defense strategies to secure your network from malware, ransomware and lateral movement.'
    },
    {
        icon: <FiSmartphone />,
        title: 'OT/IoT security',
        description: 'Help clients assess risk, build secure solutions, and provide visibility to their mission critical assets deployed across their networks.'
    },
    {
        icon: <FiTool />,
        title: 'Security Engineering',
        description: 'Help clients engineer and build products/platforms with inherent digital trust and resilience, while enabling security assurance.'
    },
    {
        icon: <FiAlertOctagon />,
        title: 'Third-Party Risk Management',
        description: 'With our TPRM services, streamline upfront due diligence to focus on critical risks related with vendors, business partners and suppliers.'
    },


]

const ICTAndCyberSecurityService = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div  
                className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div
                            style={{height: '240px'}}
                            className="content"
                            >
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p  className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ICTAndCyberSecurityService;