import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutProfessionalServices = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Overview</span></h4>
                                    <h2 className="title mt--10">Bringing Technology-Led Disruption</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>Professional Services industry is one of the largest industry segments with a market value of $1.6 Trillion, having a tremendous untapped potential for disruption. The following give us an edge over our competitors.</p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Platform Driven, Outcome based Engagement Model</li>
                                        <li><span className="icon"><FiCheck /></span> Domain Specific Service Offerings.</li>
                                        <li><span className="icon"><FiCheck /></span> Embed "Technology" as integral part of services enabling growth and high margin business.</li>
                                    </ul>
                                </ScrollAnimation>
                                {/* <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="#">More About Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation> */}
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>   
        </div>
    )
}

export default AboutProfessionalServices;
