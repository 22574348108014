import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import PortfolioOne from "./PortfolioOne";
import TabTwo from '../tab/TabTwo';
import PolicyProTab from '../tab/PolicyProTab';
import AboutOne from '../about/AboutOne';
import AboutFive from '../about/AboutFive';
import AboutSix from '../about/AboutSix';
import AbouThree from '../about/AboutThree';
import PolicyProAbout from '../about/PolicyProAbout';
import ServiceFive from '../service/ServiceFive';
import ServiceFour from '../service/ServiceFour';
import AboutTwo from '../about/AboutTwo';



const PortfolioGrid = () => {
    return (
        <>
            <SEO title="Portfolio Grid Layout || InfoMats Technologies Limited" />
            <Layout>
            <BreadcrumbOne 
                    // title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    title="IMT subsidiary companies."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Policy Pro"
                />
                
                <div className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Policy Pro"
                                            title = ""
                                            description = ""
                                        />
                                </div>
                            </div>
                            {/* <PortfolioOne Column="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio" /> */}

                            {/* <AboutTwo />      */}
                            <PolicyProAbout/>    
                            <PolicyProTab/>

                            {/* <ServiceFour/>                    */}

                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PortfolioGrid;
